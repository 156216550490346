<template>
  <div class="pl-50">
    <course-section-edit-modal
      v-if="editCourseSectionData"
      :edit-course-section-active.sync="editCourseSectionActive"
      :course-section-data.sync="editCourseSectionData"
      @refetch-data="() => $emit('refetch-data')"
    />

    <pathway-cohort-add-section-modal
      v-if="addSectionActive"
      :is-add-section-open.sync="addSectionActive"
      :cohort-data.sync="pathwayCohortData"
      :replace-section.sync="replaceSection"
      @refetch-data="() => $emit('refetch-data')"
    />

    <div class="d-flex align-items-center justify-content-start mb-75">
      <h3 class="mb-0">Sections</h3>
      <div
        @click="() => (addSectionActive = true)"
        class="d-flex align-items-center text-muted cursor-pointer ml-1"
        style="margin-top: 2px"
        v-if="$can('roster.cohort.section.add')"
      >
        <span class="mr-50">Add Section</span>
        <feather-icon icon="PlusCircleIcon" size="18" />
      </div>
    </div>

    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refSectionListTable"
        class="position-relative mb-0"
        :items.sync="sectionsData"
        responsive
        :fields="sectionFields"
        show-empty
        primary-key="id"
        empty-text="No sections assigned to this cohort"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Course -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="data.item.course">
            <b-avatar
              size="36"
              :src="data.item.course.avatar"
              :text="avatarText(data.item.course.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
              style="margin-top: 2px"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
              >
                {{ resolveCourseSectionName(data.item) }}
              </b-link>
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="d-flex align-items-center text-muted text-nowrap"
              >
                <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                <small> {{ data.item.course.name }}</small>
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveCourseSectionStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(profiles)="data">
          <span :id="`student-list-status-${data.item.id}`" style="cursor: help">{{ data.item.profiles.length }}</span>

          <student-list-statuses :target-id="`student-list-status-${data.item.id}`" :data="data.item.statistics" />
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ totalSections }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalSections }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSections"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <vue-context ref="pathwayViewSectionEditMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.section.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.section.update')"
          class="d-flex align-items-center justify-content-between"
          @click="editCourseSection(data.item)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.cohort.section.replace')"
          @click="startReplaceSection(data.item)"
          class="d-flex align-items-center justify-content-between"
        >
          <span>Replace</span>
          <feather-icon icon="RepeatIcon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.cohort.section.remove')"
          @click="removeSectionFromCohort(data.item)"
          class="d-flex align-items-center justify-content-between"
        >
          <span>Remove</span>
          <feather-icon icon="RepeatIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCourseSections from '../../../courses/sections/useCourseSections';
import { title } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import VueContext from 'vue-context';
import { onMounted, ref, watch } from '@vue/composition-api';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import moment from 'moment';
import { hexToRGB } from '@/libs/utils/color';
import CourseSectionEditModal from '../../../courses/sections/edit/CourseSectionEditModal.vue';
import StudentListStatuses from './components/StudentListStatuses.vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import PathwayCohortAddSectionModal from '@/views/apps/roster/pathways/cohorts/edit/PathwayCohortAddSectionModal.vue';

const { skin } = useAppConfig();

export default {
  components: {
    PathwayCohortAddSectionModal,
    draggable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VueContext,
    vSelect,
    CourseSectionEditModal,
    StudentListStatuses,
  },
  props: {
    pathwayCohortData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    calculateCourseColor(courseData) {
      if (courseData.metadata && courseData.metadata.design && courseData.metadata.design.color) {
        return courseData.metadata.design.color;
      }
      return '#5b5b5e';
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.25) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
    editCourseSection(data) {
      this.editCourseSectionData = data;
      this.editCourseSectionActive = true;
    },
    onRowContextMenu(item, index, event) {
      console.log(item, index, event);
      this.$refs.pathwayViewSectionEditMenu.open(event, { item });
      event.preventDefault();
    },
    removeSectionFromCohort(section) {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will remove all students in this cohort from this section, are you sure you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/removeSectionsFromPathwayCohort', {
              cohortId: this.pathwayCohortData.id,
              sectionIds: [section.id],
            })
            .then((response) => {
              if (response.status === 200) {
                setTimeout(() => this.$emit('refetch-data'), 1000);
                this.$swal({
                  icon: 'success',
                  title: 'Remove!',
                  text: 'The section has been removed',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to remove section',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
    },
    startReplaceSection(section) {
      this.replaceSection = section;
      this.addSectionActive = true;
    },
  },
  watch: {
    addSectionActive(val) {
      if (!val) this.replaceSection = null;
    },
  },
  setup(props) {
    const addSectionActive = ref(false);
    const replaceSection = ref(null);

    const sectionsData = ref([]);
    const sectionFields = ref([
      { key: 'name', label: 'Section Name', sortable: true },
      { key: 'status', sortable: true },
      {
        key: 'profiles',
        label: 'Enrolled Students',
        formatter: (value) => {
          return value.length;
        },
      },
      {
        key: 'classes',
        label: 'Classes',
        formatter: (value, key, item) => {
          if (item.classes) {
            return item.classes.length;
          }
          return 0;
        },
      },
    ]);
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalSections = ref(0);
    const totalPageCount = ref(1);

    const editCourseSectionActive = ref(false);
    const editCourseSectionData = ref(null);
    const course = ref(null);

    const pathwayCohortSections = ref(props.pathwayCohortData.sections);

    const fetchPathwayCohortSections = () => {
      // Assuming pathwayCohortSections is already sorted as per requirements
      const start = (currentPage.value - 1) * perPage.value;
      const end = start + perPage.value;

      // Apply pagination directly on pathwayCohortSections
      const paginatedSections = pathwayCohortSections.value.slice(start, end);

      // Update the sectionsData with the paginated sections
      sectionsData.value = paginatedSections;

      // Update totalSections to reflect the total number of sections
      totalSections.value = pathwayCohortSections.value.length;

      // Calculate and update the totalPageCount
      totalPageCount.value = Math.ceil(pathwayCohortSections.value.length / perPage.value);

      return sectionsData.value;
    };

    onMounted(() => {
      fetchPathwayCohortSections();
    });
    watch(
      () => props.pathwayCohortData.sections,
      (newValue) => {
        pathwayCohortSections.value = newValue;
        fetchPathwayCohortSections();
      },
    );

    const {
      perPageOptions,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionName,
    } = useCourseSections();
    return {
      fetchPathwayCohortSections,
      sectionsData,
      sectionFields,
      currentPage,
      perPage,
      totalSections,
      totalPageCount,
      perPageOptions,

      addSectionActive,
      editCourseSectionActive,
      editCourseSectionData,
      replaceSection,
      course,
      avatarText,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionStatusIcon,
      resolveCourseSectionVisibilityIcon,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageIcon,
      resolveCourseSectionDateMessageVariant,
      pathwayCohortSections,
      resolveCourseSectionName,
      title,
      skin,
      moment,
    };
  },
};
</script>
