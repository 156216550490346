<template>
  <div class="container content-p">
    <!-- Alert: No item found -->

    <b-alert variant="danger" :show="pathwayCohortData === undefined">
      <h4 class="alert-heading">Error fetching pathway cohort data</h4>
      <div class="alert-body">
        No pathway cohort found with this pathway cohort id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-pathway-cohort-list-pending' }"> Pathway Cohort List </b-link>
        for other cohorts.
      </div>
    </b-alert>

    <div v-if="pathwayCohortData" :key="pathwayCohortData.id">
      <pathway-cohort-edit-modal
        :edit-pathway-cohort-active.sync="editPathwayCohortActive"
        :pathway-cohort-data.sync="pathwayCohortData"
        @refetch-data="fetchData"
        v-if="$can('roster.cohort.update')"
      />

      <b-row class="mb-1">
        <b-col cols="12">
          <pathway-cohort-view-header
            :pathway-cohort-data="pathwayCohortData"
            :edit-pathway-cohort-active.sync="editPathwayCohortActive"
            :send-pathway-cohort-onboarding.sync="editPathwayCohortActive"
            @refetch-data="fetchData"
          />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="12">
          <pathway-cohort-view-students-list
            v-if="$can('roster.cohort.student.list')"
            :pathway-cohort-data.sync="pathwayCohortData"
            @refetch-data="fetchData"
          />
          <h3 class="mb-0 mr-50" v-else>You are not authorized to view students</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <pathway-cohort-view-actions :pathway-cohort-data="pathwayCohortData" @refetch-data="fetchData" />
        </b-col>
        <b-col cols="8">
          <pathway-cohort-view-sections
            v-if="$can('roster.section.list')"
            :pathway-cohort-data.sync="pathwayCohortData"
            @refetch-data="fetchData"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';

import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import PathwayCohortViewActions from './PathwayCohortViewActions.vue';
import PathwayCohortViewStudentsList from './PathwayCohortViewStudentsList.vue';
import PathwayCohortViewHeader from './PathwayCohortViewHeader.vue';
import PathwayCohortViewSections from './PathwayCohortViewSections.vue';
import SessionList from '@/views/apps/roster/pathways/cohorts/list/PathwayCohortList.vue';
import moment from 'moment';
import PathwayCohortEditModal from '@/views/apps/roster/pathways/cohorts/edit/PathwayCohortEditModal.vue';

export default {
  components: {
    SessionList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,

    // Local Components
    PathwayCohortEditModal,
    PathwayCohortViewActions,
    PathwayCohortViewHeader,
    PathwayCohortViewStudentsList,
    PathwayCohortViewSections,
  },
  methods: {},
  watch: {
    '$route.path'() {
      this.fetchData();
    },
  },
  setup() {
    const pathwayCohortData = ref(null);
    const editPathwayCohortActive = ref(false);

    const fetchData = () => {
      store
        .dispatch('rosterStoreModule/fetchPathwayCohortSummary', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          pathwayCohortData.value = response.data;
        })
        .catch((error) => {
          pathwayCohortData.value = undefined;
        });
    };

    fetchData();

    return {
      pathwayCohortData,
      fetchData,
      moment,
      avatarText,
      title,
      editPathwayCohortActive,
    };
  },
};
</script>
