<template>
  <b-popover
    :target="`compliance-${targetId}`"
    variant="primary"
    triggers="hover"
    placement="left"
    custom-class="rounded-circle mw-100 w-auto"
  >
    <div class="d-flex align-items-center justify-content-between mw-auto w-auto border-bottom p-75" style="gap: 1rem">
      <h5 class="font-weight-medium mb-0 w-fit">{{ studentName }}'s Compliance</h5>

      <b-badge
        :variant="`${resolveComplianceScoreVariant({
          score: sectionsData ? complianceData.scores.overall : complianceData.overall,
        })}`"
        class="text-capitalize"
      >
        {{ gradeScore({ score: sectionsData ? complianceData.scores.overall : complianceData.overall, precision: 2, percent: true }) }}
        Overall
      </b-badge>
    </div>

    <div v-if="!sectionsData" class="d-flex flex-column p-75 w-100" style="gap: 0.625rem">
      <div v-if="selectModule('attendance')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-user" class="text-info" style="width: 1rem; height: 1rem" />
          <p class="d-flex mb-0">Attendance</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('attendance').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{
            gradeScore({
              score: selectModule('attendance').score,
              precision: 2,
              percent: true,
            })
          }}
        </b-badge>
      </div>

      <div v-if="selectModule('simulations')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-atom-simple" class="text-danger" />
          <p class="d-flex mb-0">Simulations</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('simulations').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{
            gradeScore({
              score: selectModule('simulations').score,
              precision: 2,
              percent: true,
            })
          }}
        </b-badge>
      </div>

      <div v-if="selectModule('examedge')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-file-lines" class="text-warning" style="width: 1rem; height: 1rem" />
          <p class="d-flex mb-0">ExamEdge</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('examedge').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{
            gradeScore({
              score: selectModule('examedge').score,
              precision: 2,
              percent: true,
            })
          }}
        </b-badge>
      </div>

      <div v-if="selectModule('labs')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-flask-gear" style="width: 1rem; height: 1rem; color: #7367f0" />
          <p class="d-flex mb-0">Labs</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('labs').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{ gradeScore({ score: selectModule('labs').score, precision: 2, percent: true }) }}
        </b-badge>
      </div>

      <div v-if="selectModule('labs_external')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-flask-gear" style="width: 1rem; height: 1rem; color: #7367f0" />
          <p class="d-flex mb-0">Labs (external)</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('labs_external').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{ gradeScore({ score: selectModule('labs_external').score, precision: 2, percent: true }) }}
        </b-badge>
      </div>

      <div v-if="selectModule('other')" class="d-flex align-items-center justify-content-between w-100">
        <span class="d-flex align-items-center" style="gap: 0.625rem">
          <font-awesome-icon icon="fa-solid fa-flask-gear" style="width: 1rem; height: 1rem; color: #7367f0" />
          <p class="d-flex mb-0">Other</p>
        </span>
        <b-badge
          :variant="`${resolveComplianceScoreVariant({
            score: selectModule('other').score,
          })}`"
          class="d-flex text-capitalize"
        >
          {{ gradeScore({ score: selectModule('other').score, precision: 2, percent: true }) }}
        </b-badge>
      </div>
    </div>

    <div v-else-if="complianceData.sections" class="d-flex flex-column">
      <div
        v-for="section in sectionsData"
        v-if="complianceData.sections[section.id]"
        :key="section.id"
        class="d-flex flex-column p-75"
        style="gap: 0.3rem"
      >
        <p class="d-flex mb-0">{{ stripAfterPipe(section.name) }}</p>

        <div class="d-flex align-items-center" style="gap: 0.4rem">
          <b-badge :variant="`light-${resolveCourseSectionStatusVariant(section.status)}`" class="text-capitalize">
            {{ section.status }}
          </b-badge>

          <b-badge
            :variant="`${resolveComplianceScoreVariant({
              score: complianceData.sections[section.id].scores.overall,
            })}`"
            class="d-flex text-capitalize"
            style="width: fit-content"
          >
            {{
              gradeScore({
                score: complianceData.sections[section.id].scores.overall,
                precision: 2,
                percent: true,
              })
            }}
          </b-badge>
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import { BBadge, BPopover, BAvatar } from 'bootstrap-vue';
import { gradeScore } from '@core/utils/filter';
import { avatarText } from '@core/utils/filter';
import useCourseSections from '../../useCourseSections';
import useProfiles from '@/views/apps/roster/profile/useProfiles';

export default {
  components: {
    BBadge,
    BPopover,
    BAvatar,
  },
  props: {
    targetId: { type: String, required: true },
    studentName: { type: String, required: true },
    complianceData: { type: Object, required: true },
    sectionsData: { type: Array },
  },
  methods: {
    selectModule(module) {
      if (!this.complianceData || !this.complianceData.modules) return null;
      return this.complianceData.modules[module] || null;
    },
    stripAfterPipe(name) {
      if (!name) return;
      return name.replace(/ \| .*/, '');
    },
  },
  setup() {
    const { resolveComplianceScoreVariant } = useProfiles();
    const { resolveCourseSectionStatusVariant } = useCourseSections();

    return {
      resolveComplianceScoreVariant,
      resolveCourseSectionStatusVariant,
      gradeScore,
      avatarText,
    };
  },
};
</script>
