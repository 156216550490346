<template>
  <component is="div">
    <b-modal
      id="modal-lg"
      ok-title="Add to Session"
      centered
      size="lg-wider"
      :title="`Search For ${placeIn === 'pathway' ? 'Pathway Cohorts' : 'Course Sections'}`"
      ref="modal"
      @hidden="() => $emit('update:is-add-session-open', false)"
      :visible="isAddSessionOpen"
      :hide-footer="true"
      body-class="p-0"
    >
      <div>
        <b-col rows="12" class="mb-1">
          <div class="d-flex align-items-end justify-content-between px-75">
            <b-tabs nav-wrapper-class=" mb-0" nav-class="m-0" v-model="placeInActive" lazy>
              <b-tab title="Course Section">
                <template #title>
                  <lucide-icon name="Book" style="width: 14px; height: 14px" />
                  <span class="d-none d-md-block"> Pathway Cohorts</span>
                  <span class="d-block d-md-none"> Pathways</span>
                </template>
              </b-tab>
              <b-tab title="Pathway Cohort">
                <template #title>
                  <lucide-icon name="Library" style="width: 14px; height: 14px" />
                  <span class="d-none d-md-block"> Course Sections</span>
                  <span class="d-block d-md-none"> Courses</span>
                </template>
              </b-tab>
            </b-tabs>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group class="justify-content-end mb-0">
                <div class="d-flex align-items-center text-nowrap">
                  <b-form-input v-model="searchTerm" placeholder="Search " type="text" class="d-inline-block" @input="handleSearch" />
                </div>
              </b-form-group>
            </div>
          </div>
        </b-col>

        <b-table
          id="table-test"
          ref="refSessionTable"
          class="mb-0"
          :items="sessionProvider"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchTerm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :responsive="true"
        >
          <template #cell(name)="data">
            <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="placeIn === 'pathway'">
              <b-avatar
                v-if="data.item.pathway"
                size="33"
                style="margin-top: 2px"
                :src="data.item.pathway.avatar"
                :text="avatarText(data.item.pathway.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small v-if="data.item.pathway"> {{ data.item.pathway.name }}</small>
                </b-link>
              </div>
            </div>
            <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="placeIn === 'course'">
              <b-avatar
                v-if="data.item.course"
                size="33"
                style="margin-top: 2px"
                :src="data.item.course.avatar"
                :text="avatarText(data.item.course.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small v-if="data.item.course"> {{ data.item.course.name }}</small>
                </b-link>
              </div>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="`${resolvePathwayCohortStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status.split('_').join(' ') }}
            </b-badge>
          </template>

          <!-- Column: Visibility
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolvePathwayCohortVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template> -->

          <!-- Column: Total Students -->
          <template #cell(profiles)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
                data.value ? `${data.value} Student${data.value !== 1 ? 's' : ''}` : 'No Students Enrolled'
              }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button
              @click="openProvideReason(data.item)"
              variant="primary"
              size="sm"
              :disabled="checkAlreadyAssigned(data.item) || isTransferProvideReasonOpen"
              >{{ checkAlreadyAssigned(data.item) ? 'Already Enrolled' : 'Select' }}</b-button
            >
          </template>
        </b-table>

        <div class="mx-2 mb-75">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted">{{ totalResults }} Total Entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-reason"
      ok-title="Reassign"
      centered
      size="lg"
      title="Transfer Roster Profile"
      ref="modal"
      @hidden="() => (isTransferProvideReasonOpen = false)"
      :visible="isTransferProvideReasonOpen"
      @ok="assignSession"
    >
      <b-row class="mb-25">
        <b-col>
          <b-form-group label="Reassignment Reason" label-for="transfer-reason">
            <b-form-input
              id="transfer-reason"
              v-model="transferReason"
              autofocus
              :state="!!transferReason && transferReason.length > 0"
              trim
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-checkbox id="loa-switch" v-model="metadata.loa.enabled" class="custom-control-primary" switch>
        Leave of Absence
      </b-form-checkbox>

      <b-row class="mb-2" v-if="metadata.loa.enabled">
        <b-col cols="12" lg="6">
          <label>Start Date</label>
          <b-form-datepicker
            class="w-100"
            id="loa-date-start"
            :value="metadata.loa.startDate"
            @input="(val) => (metadata.loa.startDate = val)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-col>

        <b-col cols="12" lg="6">
          <label>End Date</label>
          <b-form-datepicker
            class="w-100"
            id="loa-date-end"
            :value="metadata.loa.endDate"
            @input="(val) => (metadata.loa.endDate = val)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-col>

        <div class="divider my-2"></div>
      </b-row>
    </b-modal>
  </component>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BRow,
  BTable,
  BLink,
  BTooltip,
  BTabs,
  BTab,
} from 'bootstrap-vue';

import Cleave from 'vue-cleave-component';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';

export default {
  components: {
    Cleave,
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BTooltip,
    BLink,
    BTabs,
    BTab,
  },
  data() {
    return {
      placeIn: 'pathway',
      placeInActive: null,

      searchTerm: '',
      perPage: 7,
      currentPage: 1,
      totalResults: 0,

      tableFields: [
        { key: 'name', sortable: true },
        {
          key: 'profiles',
          label: 'Students',
          formatter: (value) => {
            return value.length;
          },
        },
        // { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      sortBy: 'startDate',
      sortDesc: true,

      selectedCohort: undefined,
      enrolledCohorts: [],

      isTransferProvideReasonOpen: false,
      transferReason: '',

      metadata: { loa: { enabled: false, startDate: null, endDate: null } },

      stage: 'profile',
      profile: undefined,
      session: undefined,

      restricted: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    };
  },
  props: {
    isAddSessionOpen: {
      type: Boolean,
    },
    profileData: {
      type: Object,
      default: () => {},
    },
    defaultToSection: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['refresh'],
  watch: {
    isAddSessionOpen(val) {
      if (val) {
        this.stage = 'profile';
        if (this.defaultToSection) {
          this.placeInActive = 1;
        }
      }
    },
    profileData() {
      this.profile = this.profileData;
    },
    placeIn(val) {
      this.refreshTable();
    },
    placeInActive(val) {
      this.placeIn = val === 1 ? 'course' : 'pathway';
    },
  },
  methods: {
    refreshTable() {
      if (!this.$refs.refSessionTable) return;
      this.$refs.refSessionTable.refresh();
    },
    checkAlreadyAssigned(item) {
      if (!item) return;
      if (this.placeIn === 'course') {
        if (!item.type === 'pathway') return false;
        return this.enrolledCohorts.includes(item.courseCohort.id);
      }
      if (this.placeIn === 'pathway') {
        if (!item.type === 'course') return false;
        return this.enrolledCohorts.includes(item.id);
      }
    },
    assignPathwayCohort(pathwayCohort) {
      this.selectedCohort = pathwayCohort;
      this.isAddProfileMetadataOpen = true;
      this.$emit('update:is-add-session-open', false);
    },
    assignCourseSection(section) {
      this.selectedCohort = section.courseCohort;
      this.isAddProfileMetadataOpen = true;
      this.$emit('update:is-add-session-open', false);
    },
    sessionProvider(ctx) {
      // const { pathway, subject } = this.profile;

      // let adl = {};
      // if (this.overridePathway) {
      //   adl.pathway = this.overridePathway;
      // } else if (this.overrideSubject) {
      //   adl.subject = this.overrideSubject;
      // } else if (pathway) {
      //   adl.pathway = pathway.id || pathway;
      // } else if (subject) {
      //   adl.subject = subject.id || subject;
      // }

      // if (!this.restricted) {
      //   delete adl.pathway;
      //   delete adl.subject;
      // }

      const storeAction = this.placeIn === 'pathway' ? 'fetchPathwayCohortList' : 'fetchCourseSectionList';
      const promise = store.dispatch(`rosterStoreModule/${storeAction}`, {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'startDate'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
        populate: 'pathway,course,courseCohort',
        // ...restrictedQp,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },

    openProvideReason(cohort) {
      this.selectedCohort = { ...cohort, type: this.placeIn };
      this.transferReason = '';
      this.isTransferProvideReasonOpen = true;
    },
    assignSession() {
      let newCohort;
      let newName = this.selectedCohort.name;

      if (this.placeIn === 'pathway') {
        let newName = this.resolvePathwayCohortName(this.selectedCohort) + ' ' + this.selectedCohort.pathway.name;
      } else if (this.placeIn === 'course') {
        let newName = this.resolveCourseSectionName(this.selectedCohort) + ' ' + this.selectedCohort.course.name;
      }

      if (this.selectedCohort.type === 'course') {
        newCohort = this.selectedCohort.courseCohort;
      }
      if (this.selectedCohort.type === 'pathway') {
        newCohort = this.selectedCohort;
      }

      const storeActionAdd = this.placeIn === 'pathway' ? 'addStudentsToPathwayCohort' : 'addStudentsToCourseCohort';

      store
        .dispatch('rosterStoreModule/transferStudentToAnotherCohort', {
          cohortId: newCohort.id,
          profileId: this.profile.id,
          reassignReason: `${this.transferReason} - Reassigned to ${newName}`,
          loa: this.metadata.loa.enabled ? this.metadata.loa : { enabled: false },
          metadata: this.metadata,
        })
        .then((response) => {
          const { data: session } = response;

          this.$emit('update:is-add-session-open', false);
          this.$emit('refresh', true);
          this.stage = 'session';

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added profile to session',
              text: 'This student has been assigned to this session',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to assign profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },
  mounted() {},
  setup({ props }) {
    const sessionData = ref(null);

    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant, resolvePathwayCohortVisibilityVariant } = usePathwayCohorts();

    const { resolveCourseSectionName } = useCourseSections();

    const toast = useToast();

    return {
      toast,
      title,
      moment,
      avatarText,
      resolvePathwayCohortName,
      resolveCourseSectionName,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortVisibilityVariant,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
