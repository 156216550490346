<template>
  <component is="div">
    <b-modal
      id="modal-lg"
      centered
      size="lg-wider"
      :title="`${this.replaceSection ? 'Replace Section In' : 'Add Section To'} ${cohortData.name}`"
      ref="modal"
      @hidden="() => $emit('update:is-add-section-open', false)"
      :visible="isAddSectionOpen"
      :hide-footer="true"
      body-class="p-0"
    >
      <div>
        <b-col rows="12" class="mb-1">
          <div class="d-flex align-items-end justify-content-between px-75">
            <b-tabs nav-wrapper-class=" mb-0" nav-class="m-0" lazy>
              <b-tab title="Course Section">
                <template #title>
                  <lucide-icon name="Book" style="width: 14px; height: 14px" />
                  <span class="d-none d-md-block">Sections</span>
                </template>
              </b-tab>
            </b-tabs>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group class="justify-content-end mb-0">
                <div class="d-flex align-items-center text-nowrap">
                  <b-form-input v-model="searchTerm" placeholder="Search " type="text" class="d-inline-block" @input="handleSearch" />
                </div>
              </b-form-group>
            </div>
          </div>
        </b-col>

        <b-table
          id="table-test"
          ref="refSectionTable"
          class="mb-0"
          :items="sectionProvider"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchTerm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :responsive="true"
        >
          <template #cell(name)="data">
            <div class="d-flex align-items-center" style="gap: 0.625rem">
              <b-avatar
                v-if="data.item.course"
                size="33"
                style="margin-top: 2px"
                :src="data.item.course.avatar"
                :text="avatarText(data.item.course.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-roster-course-view', params: { id: data.item.course.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolveCourseSectionName(data.item) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small v-if="data.item.course"> {{ data.item.course.name }}</small>
                </b-link>
              </div>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="`${resolveCourseSectionStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status.split('_').join(' ') }}
            </b-badge>
          </template>

          <!-- Column: Visibility
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolvePathwayCohortVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template> -->

          <!-- Column: Total Students -->
          <template #cell(profiles)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
                data.value ? `${data.value} Student${data.value !== 1 ? 's' : ''}` : 'No Students Enrolled'
              }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button @click="assignSection(data.item)" variant="primary" size="sm" :disabled="checkAlreadyAssigned(data.item)"
              >{{ checkAlreadyAssigned(data.item) ? 'Already In Cohort' : 'Select' }}
            </b-button>
          </template>
        </b-table>

        <div class="mx-2 mb-75">
          <b-row>
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="mb-0 text-muted">{{ totalResults }} Total Entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-sm-0 ml-lg-50"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
} from 'bootstrap-vue';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';

export default {
  components: {
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BLink,
    BTabs,
    BTab,
  },
  data() {
    return {
      searchTerm: '',
      perPage: 7,
      currentPage: 1,
      totalResults: 0,

      tableFields: [
        { key: 'name', sortable: true },
        {
          key: 'profiles',
          label: 'Students',
          formatter: (value) => {
            return value.length;
          },
        },
        // { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      sortBy: 'startDate',
      sortDesc: true,

      profile: undefined,
      section: undefined,

      restricted: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    };
  },
  props: {
    isAddSectionOpen: {
      type: Boolean,
    },
    cohortData: {
      type: Object,
    },
    replaceSection: {
      // object of the section we're replacing. fixme: maybe use an ID instead
      type: Object,
    },
  },
  emits: ['refresh'],
  methods: {
    refreshTable() {
      if (!this.$refs.refSectionTable) return;
      this.$refs.refSectionTable.refresh();
    },
    checkAlreadyAssigned(item) {
      if (!item) return;
      return this.cohortData.sections.find((section) => section.id === item.id);
    },
    sectionProvider(ctx) {
      const promise = store.dispatch('rosterStoreModule/fetchCourseSectionList', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'startDate'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
        populate: 'course,courseCohort',
        // ...restrictedQp,
        course: this.replaceSection ? this.replaceSection.course.id : undefined,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching section list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },

    assignSection(section) {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will add all students in this cohort to this section, are you sure you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${this.replaceSection ? 'replace' : 'add'}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          (this.replaceSection
            ? store.dispatch('rosterStoreModule/replaceSectionInCohort', {
                cohortId: this.cohortData.id,
                fromId: this.replaceSection.id,
                toId: section.id,
              })
            : store.dispatch('rosterStoreModule/addSectionsToCohort', {
                cohortId: this.cohortData.id,
                sectionIds: [section.id],
              })
          )
            .then((response) => {
              const { data, status } = response;
              console.log({ data, status });

              setTimeout(() => {
                this.$emit('update:is-add-section-open', false);
                this.$emit('refetch-data', true);
              }, 500);

              return this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'EditIcon',
                  variant: 'success',

                  title: 'Added section to cohort',
                  text: 'The section has been assigned to this cohort',
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to assign section',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },
  mounted() {},
  setup({ props }) {
    const { resolveCourseSectionName, resolveCourseSectionStatusVariant, resolveCourseSectionVisibilityVariant } = useCourseSections();

    const toast = useToast();

    return {
      toast,
      title,
      moment,
      avatarText,
      resolveCourseSectionName,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionVisibilityVariant,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
