<template>
  <b-popover :target="`student-${targetId}`" triggers="hover" delay="200" custom-class="rounded-circle mw-100 w-auto">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between pt-75 px-75 pb-50 border-bottom">
        <span class="d-flex flex-column mr-2">
          <h5 class="font-weight-medium mb-0 w-fit">{{ data.student.fullName }}</h5>
          <p class="text-muted mb-0">{{ data.student.myIpId }}</p>
        </span>

        <span>
          <b-badge variant="light-success" class="text-capitalize">
            {{ title(data.attributes.fundingSource) }}
          </b-badge>
        </span>
      </div>

      <div class="d-flex flex-column p-75" style="gap: 0.3rem">
        <span class="d-flex align-items-center" style="gap: 0.5rem">
          <font-awesome-icon icon="fa-regular fa-envelope" class="text-info" />
          <span class="text-muted">{{ data.student.email }}</span>
        </span>
        <span class="d-flex align-items-center" style="gap: 0.5rem">
          <font-awesome-icon icon="fa-regular fa-phone" class="text-success" />
          <span class="text-muted">{{ formatPhoneNumber(data.student.phoneNumber) }}</span>
        </span>
      </div>

      <!-- Temporary disabled due to some tables not having necessary metadata in the provided { student } -->
      <div v-if="false" class="d-flex flex-column p-75 border-bottom" style="gap: 0.3rem">
        <table class="d-flex align-items-center" style="gap: 0.5rem">
          <tr>
            <th class="pb-50">
              <feather-icon icon="ShieldIcon" class="mr-75" />
              <span class="font-weight-bold">MyIP</span>
            </th>
            <td class="pb-50 pl-2 text-capitalize">
              <b-badge pill class="profile-badge mr-25" :variant="!!metadata.myIpUserId ? 'success' : 'danger'">
                {{ !!metadata.myIpUserId ? 'Yes' : 'No' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-0">
              <feather-icon icon="BookIcon" class="mr-75" />
              <span class="font-weight-bold">Learn</span>
            </th>
            <td class="pb-0 pl-2 text-capitalize">
              <b-badge pill class="profile-badge mr-25" :variant="!!metadata.learnId ? 'success' : 'danger'">
                {{ !!metadata.learnId ? 'Yes' : 'No' }}
              </b-badge>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </b-popover>
</template>

<script>
import { BBadge, BPopover } from 'bootstrap-vue';
import { gradeScore, title } from '@core/utils/filter';

export default {
  components: {
    BBadge,
    BPopover,
  },
  props: {
    targetId: { type: String, required: true },
    data: { type: Object, required: true, default: { metadata: {} } },
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      const countryCode = phoneNumber.slice(0, 2);
      const areaCode = phoneNumber.slice(2, 5);
      const middlePart = phoneNumber.slice(5, 8);
      const lastPart = phoneNumber.slice(8);
      return `${countryCode} (${areaCode}) ${middlePart}-${lastPart}`;
    },
  },
  computed: {
    metadata() {
      return this.data.student && this.data.student.metadata ? this.data.student.metadata : {};
    },
  },
  setup() {
    return {
      gradeScore,
      title,
    };
  },
};
</script>
