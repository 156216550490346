<template>
  <b-modal
    id="modal-lg"
    ok-title="Add Selected Student(s) to Session"
    centered
    size="lg-wider"
    title="Search for Students"
    ref="modal"
    @ok="addStudentToClass"
    @hide="$emit('update:is-add-new-student-active', false)"
  >
    <b-card no-body v-if="isSidebarActive" class="border-0 pt-2 mb-0">
      <!-- all input -->
      <div class="custom-search d-flex justify-content-end px-2">
        <b-form-group class="w-40">
          <div class="d-flex align-items-center">
            <b-form-input v-model="searchTerm" placeholder="Search User" type="text" class="d-inline-block" @input="handleSearch" />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        ref="student-table"
        mode="remote"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{ enabled: true }"
        @on-sort-change="onSortChange"
        styleClass="vgt-table student-table bordered"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Student Id -->
          <span v-if="props.column.field === 'studentId'">
            {{ props.row.student.myIpId }}
          </span>

          <!-- Column: Name -->
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            <b-avatar :src="props.row.student.avatar" class="mx-1 b-avatar-two" />
            <span class="text-nowrap">{{ props.row.student.fullName }}</span>
          </span>

          <!-- Column: Student Id -->
          <span v-else-if="props.column.field === 'email'">
            {{ props.row.student.email }}
          </span>

          <!-- Column: Student Id -->
          <span v-else-if="props.column.field === 'createdAt'">
            {{ moment(props.row.createdAt).format('L') }}
          </span>

          <!-- Column: Funding Source -->
          <span v-else-if="props.column.field === 'fundingSource'">
            {{ title(props.row.attributes.fundingSource) }}
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap pt-1 pb-1 bg-dark-lightest">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 pl-2">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="pages" class="mx-1" @input="handlePageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div class="d-flex align-items-center pr-2">
              <b-pagination
                :value="currentPage"
                :total-rows="totalUsers"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal id="modal-select2" title="Basic Modal" ok-title="submit" cancel-variant="outline-secondary">
      <b-form>
        <b-form-group label="Enter Name" label-for="name">
          <b-form-input id="name" placeholder="Enter name" />
        </b-form-group>
        <b-form-group label="Choose the country" label-for="vue-select">
          <!-- v-model="selected" :options="option" -->
          <v-select id="vue-select" />
        </b-form-group>
        <b-form-group label="Zip Code" label-for="zip-code">
          <b-form-input id="zip-code" type="number" placeholder="Zip Code" />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-modal>
</template>

<script>
import { BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BCard } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      totalPages: 0,
      totalUsers: 0,
      dir: false,
      pages: ['3', '5', '10', '25'],
      columns: [
        {
          label: 'Student ID',
          field: 'studentId',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'F.S.',
          field: 'fundingSource',
        },
        {
          label: 'Created',
          field: 'createdAt',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [],
      currentPage: 1,
    };
  },
  computed: {
    isSidebarActive() {
      return this.isAddNewStudentActive;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-info',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  props: {
    isAddNewStudentActive: {
      type: Boolean,
      default: false,
    },
    sessionData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    isAddNewStudentActive: function (value) {
      console.log('update:isAddNewStudentActive', value);
      if (value) {
        this.$refs['modal'].show();
      } else {
        this.$refs['modal'].hide();
      }
    },
  },
  mounted() {
    this.fetchStudents();
  },
  methods: {
    async addStudentToClass() {
      const selected = this.$refs['student-table'].selectedRows;
      const studentIds = selected.map((student) => student.id);

      const response = await store
        .dispatch('rosterStoreModule/addStudentsToPathwayCohort', {
          sessionId: this.sessionData.id,
          profileIds: studentIds,
        })
        .catch((error) => {
          console.error(`failed to add students to class`, error);
        });

      if (!response) return false;
      this.$emit('refreshStudents');

      console.log(`added students`, response.data);
    },

    handleSearch(searching) {
      this.log.push(`The user searched for: ${searching}`);
      this.fetchStudents();
    },
    handleChangePage(page) {
      this.log.push(`The user changed the page to: ${page}`);
      this.currentPage = page;
      this.fetchStudents();
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`);
      this.pageLength = active;
      this.fetchStudents();
    },
    onSortChange(params) {
      this.log.push(`the user ordered:  ${params[0].type}`);
      this.fetchStudents();
    },

    fetchStudents() {
      store
        .dispatch('rosterStoreModule/fetchProfiles', {
          search: this.searchTerm,
          limit: this.pageLength,
          page: this.currentPage,
          sortBy: 'createdAt:desc',

          session: 'null',
          populate: 'student',
        })
        .then((response) => {
          const { results, totalResults } = response.data;

          this.rows = results;

          this.totalUsers = totalResults;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  setup() {
    const sessionData = ref(null);

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>

<style lang="scss">
.student-table {
  width: 100%;
  thead {
    background-color: #13181e;
    border-bottom: 1px solid #292b38;
    border-top: 1px solid #292b38;

    th {
      color: white;
      padding: 1rem 0rem 1rem 0rem;

      &:nth-child(1) {
        padding-left: 1rem;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #292b38;

      &:nth-child(even) {
        background-color: #1a2029;
      }

      &:nth-child(odd) {
        background-color: #1a2029;
      }
    }

    td {
      padding: 5px;
    }

    th {
      &:nth-child(1) {
        padding-left: 1rem;
      }
    }
  }
}

.b-avatar-two {
  background-color: #1d222d !important;
}
</style>
